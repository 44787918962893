<template>
  <div class="liveLottery">
    <div v-title
         data-title="棋牌存送"></div>

    <!-- <van-nav-bar title="棋牌存送"
                 left-arrow
                 :fixed="true"
                 @click-left="gotoback" /> -->

    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-Chesssend.jpg`" />
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img"><img src="../assets/img/pcbb/rules_1.png"
             v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`"
             v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动条件 -->
        <div class="rule-condition">
          <div class="rule-condition-title"></div>
          <div class="rule-acontents">
            <div class="title-item">
              <p>申请规则:</p>
              <span style="color:var(--li-colortext);">新会员在完善实名后，并且存款后12小时内不能进行任何投注才能申请，经工作人员审核后系统自动发放。</span>
            </div>
          </div>
        </div>

        <!-- 活动内容 -->
        <div class="rule-content">
          <div class="rule-content-title"></div>
          <table>
            <thead>
              <tr style="background: linear-gradient(90deg, #4581fa, #63b3fc)">
                <td style="
                        color: #fff;
                        border-top-left-radius: 10px;
                        border-right: 1px solid #e2d2bf;
                      ">
                  项目
                </td>
                <td style="color: #fff; border-top-right-radius: 10px">条件</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>存款金额</td>
                <td>{{ datalist.dpt_money }} 以上</td>
              </tr>
              <tr>
                <td>红利比例</td>
                <td>{{ datalist.bonus_rate }}%</td>
              </tr>
              <tr>
                <td>最高奖金</td>
                <td>{{ datalist.max_bonus }}</td>
              </tr>
              <tr>
                <td>流水限制</td>
                <td>{{ datalist.beat_limit }} 倍</td>
              </tr>
              <tr>
                <td>次数限制</td>
                <td>每个账号仅限{{ datalist.times_limit || 1 }}次</td>
              </tr>
            </tbody>
          </table>

          <div class="btn-box">
            <div @click="applyWay">立即申请</div>
          </div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                指定场馆：瓦力棋牌、幸运棋牌、贝宝棋牌、OB棋牌、KY棋牌、乐游棋牌。
              </li>
              <li>
                <span>2.</span>
                每位新用户可申请1次68％奖金，有效投注额达到【（本金+红利）x15倍流水】即可提款。
              </li>
              <li>
                <span>3.</span>
                存款后当天申请才能生效。
              </li>
              <li>
                <span>4.</span>
                在申请此优惠前，请您先完善真实姓名、手机号码、银行卡等个人信息；并且存款后请立即提交申请，不要进入任何游戏场馆投注后再提交申请则视为无效。
              </li>
              <li>
                <span>5.</span>
                该优惠活动成功申请后不能取消，必须打满流水才能提款。
              </li>
              <li>
                <span>6.</span>
                体育存送、真人存送、电竞存送、棋牌存送等活动不能同时申请。
              </li>
              <li>
                <span>7.</span>
                若发现有套利客户，对冲或不诚实获取盈利之行为，将取消其优惠资格。
              </li>
              <li>
                <span>8.</span>
                本活动只针对娱乐性质的会员，同一手机号码、电子邮箱、相同银行卡、同一个IP地址、同一通信地址、同一台设备，只能由一位会员使用，若发现重复行为，将无限期保留审核、扣回礼品及所产生的利润之权利。
              </li>
              <li>
                <span>9.</span>
                本活动中任何用户或团体以不正常的方式套取活动优惠，我司保留在不通知的情况下冻结或关闭账号使用的权力，并不退还款项，且用户会被列入黑名单。
              </li>
              <li>
                <span>10.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>

    <van-popup v-model="showPicker"
               round
               position="bottom">
      <van-picker title="请选择场馆"
                  show-toolbar
                  value-key="name"
                  :columns="datalist.youxi"
                  @confirm="confirm"
                  @cancel="cancel" />
    </van-popup>
  </div>
</template>

<script>
import { getdata } from '../network/api'
import { Toast } from 'vant'

export default {
  data() {
    return {
      datalist: {},
      showPicker: false,
      cueTheme: ''

    }
  },
  methods: {
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true'
            }
          })
        })
      }
    },
    getdata() {
      let data = '?type=ChessDptBonus'
      getdata(data).then((res) => {
        if (res.status_code === 200) {
          this.datalist = res.message
        }
      })
    },
    applyWay() {
      this.showPicker = true
    },
    confirm(e) {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          // Toast('该活动仅在app开发,请下载APP')
          window.parent.postMessage(
            {
              status: 'ok',
              params: {
                urlApply: 'vipActapplyobject',
                actobject: {
                  act: 'CdptBonus',
                  gameid: e.id
                },
                urlType: window.localStorage.getItem('urlType'),
              },
            },
            '*'
          )
        } else {
          uni.postMessage({
            data: {
              urlApply: 'vipActapply',
              actname: 'CdptBonus',
              gameid: e.id
            }
          })
          // uni.postMessage({
          //   data: {
          //     urlApply: 'vipActapplyobject',
          //     actobject: {
          //       act: 'CdptBonus',
          //       gameid: e.id
          //     },
          //   }
          // })
        }
        this.showPicker = false
      })
    },
    cancel() {
      this.showPicker = false
    }
  },
  mounted() {
    this.cueTheme = window.localStorage.getItem('cueTheme')

    this.getdata()
  }
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

/deep/ .van-nav-bar .van-icon {
  color: #000;
}

.van-nav-bar .van-icon {
  color: #000;
}

body {
  background-color: #f5f5f5;
}

.liveLottery {
  .imgbox {
    // margin: 10px 20px;
    // margin-top: 46px;
    // border-radius: 8px;
    img {
      // border-radius: 8px;
      width: 100%;
    }
  }

  .activityRules {
    margin: 10px;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    padding-bottom: 5px;

    .title {
      width: 100%;
      padding: 10px 0;
      text-align: center;
      color: red;
      position: relative;
    }

    ul li {
      color: #000;
      margin-bottom: 16px;
      position: relative;
      padding-left: 16px;
      font-size: 14px;
      line-height: 1.5em;

      > span {
        text-align: center;
        position: absolute;
        top: 0px;
        left: 0;
      }
    }
  }
}

.btn-box {
  padding-bottom: 5px;

  > div {
    font-family: PingFang SC;
    font-weight: 400;

    &:nth-child(1) {
      height: 40px;
      color: #fff;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      background: linear-gradient(0deg, #2a6afd, #63c6ff);
      margin: 25px 0px 15px 0px;
    }

    &:nth-child(2) {
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #2a6afd;
      color: #418beb;
      margin: 15px 0px 15px 0px;
    }
  }
}
</style>
